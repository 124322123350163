export enum FeatureFlags {
  TEST = 'test-flag', // keep for unit tests
  ASL = 'ca-2527-application-singup-and-login',
  SSO = 'feature-social-sign-on',
}

export const isFlagActive = (flag: FeatureFlags): boolean => {
  const value = localStorage.getItem(flag);
  return Boolean(value);
};
