<template>
  <rs-container>
    <h1 class="font-weight-medium ma-0 primaryHeading" v-t="'Instructions Sent!'"></h1>
    <p class="font-weight-regular mt-3 mb-5 subText" v-t="'LTK Influencer PLATFORM'"></p>
    <p class="paragraphs" v-t="'Instructions for resetting your password have been sent to your email address.'"></p>
    <p
      class="paragraphs"
      :class="{ 'mb-5': $vuetify.breakpoint.smAndDown }"
      v-t="'You’ll receive this email within 5 minutes. Be sure to check your spam folder, too.'"
    ></p>
  </rs-container>
</template>

<script lang="ts">
export default {
  name: 'ConfirmEmail',
};
</script>

<style lang="scss" scoped>
.paragraphs {
  font-size: 14px !important;
  color: #333333;
}
</style>
