<template>
  <div class="sso-buttons" data-testid="sso-buttons">
    <div class="sso-buttons__divider">
      <span
        v-t="'or'"
        translate-comment="Users can login with email or social login"
        class="sso-buttons__divider--text"
      ></span>
    </div>
    <div class="sso-buttons__buttons">
      <button
        class="rs-button rs-button-secondary"
        data-testid="sso-apple"
        @click="clickSSOLogin(ssoProviders.apple.connection)"
      >
        <i aria-hidden="true" class="icon icon-apple"></i><span v-t="'Continue with Apple'"></span>
      </button>
      <button
        class="rs-button rs-button-secondary"
        data-testid="sso-google"
        @click="clickSSOLogin(ssoProviders.google.connection)"
      >
        <i aria-hidden="true" class="icon icon-google"></i><span v-t="'Continue with Google'"></span>
      </button>
      <button
        class="rs-button rs-button-secondary"
        data-testid="sso-facebook"
        @click="clickSSOLogin(ssoProviders.facebook.connection)"
      >
        <rs-icon size="16" color="#1877F2">rsfont-facebook</rs-icon><span v-t="'Continue with Facebook'"></span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue';
import useSSOAuthentication, { ssoProviders } from '@/composables/useSSOAuthentication';

export default defineComponent({
  name: 'SsoButtons',
  props: {
    loginScreen: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { login } = useSSOAuthentication();
    const vm = getCurrentInstance();
    const { $amplitude } = vm?.proxy as Vue;

    const clickSSOLogin = async (providerName: string) => {
      const eventSuccess = {
        event_type: props.loginScreen ? '/influencer-auth/login/success' : '/auth/signup/success',
        event_properties: { referrer: props.loginScreen ? 'login' : 'signup', method: providerName },
      };
      const eventFailure = {
        event_type: props.loginScreen ? '/influencer-auth/login/fail' : '/auth/signup/failure',
        event_properties: { referrer: props.loginScreen ? 'login' : 'signup', method: providerName },
      };

      try {
        $amplitude?.track(eventSuccess); // TODO: Will need to move this into the composable b/c I don't want success to fire on failure
        await login(providerName, props.loginScreen ? 'login' : 'signup');
      } catch (error) {
        $amplitude?.track(eventFailure);
      }
    };

    return { clickSSOLogin, ssoProviders };
  },
});
</script>

<style lang="scss">
.sso-buttons {
  &__divider {
    position: relative;
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 24px;

    &:before {
      content: ' ';
      border-top: 1px solid #9e9e9e;
      top: 10px;
      left: 0;
      right: 0;
      position: absolute;
    }

    &--text {
      background: #fafafa;
      padding: 0 10px;
      text-transform: uppercase;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.07px;
      display: inline-block;
      position: relative;
    }
  }
  &__buttons {
    .rs-button {
      margin-bottom: 24px;
      &:last {
        margin-bottom: 0;
      }
    }
    .icon {
      width: 14px;
      height: 14px;
      margin-right: 8px;
      display: inline-block;
      background-size: contain;
      position: relative;
      &.icon-apple {
        background-image: url('../assets/apple.svg');
        top: 1px;
      }
      &.icon-google {
        background-image: url('../assets/google-icon-min.svg');
        top: 2px;
      }
    }
    .v-icon {
      margin-right: 8px;
    }
  }
}
</style>
