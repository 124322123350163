import Vue from 'vue';
import Router, { Route, type RouteConfig } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import SignupPage from '@/views/SignupPage.vue';
import ConfirmEmail from '@/components/ConfirmEmail.vue';
import { routeNames } from './types';

import { AUTH0_PROMPT_PARAM_NAME, AUTH0_SCREEN_HINT_PARAM_NAME } from '@/utils/constants';
import { isNotEmpty } from '@/utils/isEmpty';
import { getFirstQueryParam } from '@/utils/getFirstQueryParam';
import { isFlagActive, FeatureFlags } from './utils/featureFlags';

Vue.use(Router);

// Updating some routes to follow Auth0's Universal login experience
// https://auth0.com/docs/authenticate/login/auth0-universal-login/universal-login-vs-classic-login/universal-experience#login
const routes: RouteConfig[] = [
  {
    name: routeNames.LOGIN,
    path: '/?prompt=login',
    component: LoginPage,
    props: (route) => ({ sentFrom: route.query.sentFrom }),
  },
  {
    name: routeNames.SIGNUP,
    path: '/?prompt=signup',
    component: SignupPage,
  },
  {
    name: routeNames.FORGOT_PASSWORD,
    path: '/?prompt=forgot-password',
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_SIGIL_URL}/forgot_password/?client_id=UnVU3dMRvktGgVdWJWi05KpKrSVhj6smmfhanudB`;
    },
  },
  {
    name: routeNames.CONFIRMATION,
    path: '/?prompt=confirmation',
    component: ConfirmEmail,
    redirect: () => ({ name: routeNames.LOGIN }), // TODO: Confirm email view update when this is ready for go
  },
  {
    name: routeNames.NOT_FOUND,
    path: '*',
    redirect(to) {
      const name =
        getFirstQueryParam(to, AUTH0_SCREEN_HINT_PARAM_NAME) ??
        getFirstQueryParam(to, AUTH0_PROMPT_PARAM_NAME) ??
        routeNames.LOGIN;

      // Ensure router doesn't fall into a loop with unrecognized named routes
      return { name: validRouteNames.includes(name) ? name : routeNames.LOGIN };
    },
  },
];
const validRouteNames = routes.filter(({ name }) => name && name !== routeNames.NOT_FOUND).map(({ name }) => name);

const router = new Router({
  /**
   * Use Abstract router to ignore browser url changes since auth0 pages can only exist on a single page (login)
   * and vue-router doesn't really like to do routing based on query params. It pretends to not recognize a query
   * in the path param, and therefore duplicate the `?` if navigating with additional query params, like auth0 state
   */
  mode: 'abstract',
  base: process.env.BASE_URL,
  routes,
});

// Handle browser history navigation by triggering protected router history APIs
// (similar to how they do internally) https://github.com/vuejs/vue-router/blob/dev/src/history/html5.js#L48
let isbrowserNavigation = false;
// eslint-disable-next-line
window.addEventListener('popstate', (_event) => {
  isbrowserNavigation = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (router as any).history.transitionTo(globalThis.location.href);
});

/**
 * Iterate through each route search param to add to the browser URL for history and
 */
const addRouteQueryToUrl = (url: URL, route: Route) => {
  Object.keys(route.query).forEach((key) => {
    const param = route.query[key];
    if (param === null) {
      url.searchParams.delete(key);
      return;
    }

    if (Array.isArray(param)) {
      param.filter(isNotEmpty).forEach((param) => (param ? url.searchParams.append(key, param) : ''));
    } else {
      url.searchParams.set(key, param);
    }
  });
};

// Sync URL after each route navigation
router.beforeEach((to, from, next) => {
  /**
   * When handling browser navigation, the URL is already synchronized, so no extra steps are necessary.
   * Additionally, using pushState will break the forward button as new history has been added rather
   * than time traveling through the current timeline.
   */
  if (isbrowserNavigation === true) {
    isbrowserNavigation = false;
    return next();
  }

  const url = new URL(window.location.href);
  /**
   * Sync new params query params with new route, preserving the existing params. This saves us
   * from needing to explicitly enumerate all existing query params in `to` links. If a param
   * needs to be removed, setting it to `null` in the `to` route's query map will clear it out.
   */
  addRouteQueryToUrl(url, to);

  // Update when not on the first page first page load
  if (from.name !== null) {
    // Prefer updating screen hint param if it already exists, otherwise use the prompt parameter
    const paramName = url.searchParams.has(AUTH0_SCREEN_HINT_PARAM_NAME)
      ? AUTH0_SCREEN_HINT_PARAM_NAME
      : AUTH0_PROMPT_PARAM_NAME;
    url.searchParams.set(paramName, to.name ?? routeNames.LOGIN);
  }

  window.history.pushState({}, '', url.toString());

  // TODO: remove if block for launch of ASL
  const hasASLFeatureFlag = isFlagActive(FeatureFlags.ASL);
  if (to.name === routeNames.SIGNUP && !hasASLFeatureFlag) {
    return next({ name: routeNames.LOGIN, query: { [AUTH0_PROMPT_PARAM_NAME]: 'login' } });
  }
  return next();
});

export default router;
