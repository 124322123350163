<template>
  <creator-login-wrapper :form-width="hasASLFeatureFlag ? '420px' : '320px'">
    <rs-container>
      <rs-layout class="column animation" :class="{ 'new-styles': hasASLFeatureFlag }" data-testid="content-wrapper">
        <rs-flex
          shrink
          :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }"
          v-if="!hasASLFeatureFlag"
          data-testid="old-content"
        >
          <div v-t="'LTK CREATOR'" class="mb-2 platform-text" data-comment="Platform"></div>
          <h1
            data-test-id="influencer-login-title"
            v-if="collabsView"
            class="primary-heading"
            v-t="'Collaborations Login'"
          ></h1>
          <h1 data-test-id="default-login-title" v-else class="primary-heading" v-t="'Log in'"></h1>
          <div>
            <p class="mt-2 mb-3 welcome-text">
              <span
                data-test-id="influencer-login-text"
                v-if="collabsView"
                v-t="'Welcome to Brand Collaborations! Please use your LTK Creator credentials to log in.'"
              ></span>
              <span
                data-test-id="default-login-text"
                v-else
                v-t="'Welcome to LTK Creator, a platform for creators!'"
              ></span>
              <template v-if="!shopperLoginView">
                <br />
                <span v-t="'If you are a Brand, log into your'" data-test-id="brand-click"></span>
                <span>&nbsp;</span>
                <a
                  href="https://brands.rewardstyle.com/"
                  target="_blank"
                  rel="noreferrer nofollow"
                  v-t="'LTK Connect account'"
                ></a>
              </template>
            </p>
          </div>
        </rs-flex>
        <rs-flex
          shrink
          :class="{ 'mt-4': $vuetify.breakpoint.smAndDown, 'text-center': true }"
          v-else
          data-testid="new-content"
        >
          <rs-icon color="#000" size="40" v-if="!$vuetify.breakpoint.smAndDown">rsfont-ltk-logo_v2</rs-icon>
          <h1
            data-test-id="influencer-login-title"
            class="primary-heading"
            v-t="'Log in to Brand Collaborations'"
            v-if="collabsView"
          ></h1>
          <h1
            data-test-id="shopper-login-title"
            class="primary-heading"
            v-t="'Log in'"
            v-else-if="shopperLoginView"
          ></h1>
          <h1 data-test-id="default-login-title" class="primary-heading" v-t="'Log in to LTK Creator'" v-else></h1>
          <p class="welcome-text" v-if="!shopperLoginView" data-test-id="creator-welcome-text">
            <span v-t="'If you are a Brand, log in to'"></span>
            <span>&nbsp;</span>
            <a href="https://brands.rewardstyle.com/" target="_blank" rel="noreferrer nofollow" v-t="'LTK connect'"></a>
          </p>
          <p class="welcome-text" v-else data-test-id="shopper-welcome-text">
            <span v-t="'Log in to your LTK Shopper account'"></span>
          </p>
        </rs-flex>
      </rs-layout>
      <div class="mt-3 animation">
        <login-form />
      </div>
    </rs-container>
  </creator-login-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import CreatorLoginWrapper from '@/components/CreatorLoginWrapper.vue';
import LoginForm from '@/components/LoginForm.vue';
import { isFlagActive, FeatureFlags } from '@/utils/featureFlags';

export default defineComponent({
  components: {
    CreatorLoginWrapper,
    LoginForm,
  },
  props: {
    sentFrom: {
      type: String,
      default: '',
    },
  },
  name: 'LoginPage',
  setup(props) {
    // NOTE: The logic in the template is backwards, but it's set in stone b/c of redirects.
    // Collabs is influencer_platform and default is influencer/creator login
    const hasASLFeatureFlag = computed(() => isFlagActive(FeatureFlags.ASL));
    const collabsView = computed(() => props.sentFrom === 'influencer_platform');
    const shopperLoginView = computed(() => props.sentFrom === 'shopper_account' && hasASLFeatureFlag.value);

    return { collabsView, shopperLoginView, hasASLFeatureFlag };
  },
});
</script>

<style lang="scss" scoped>
.platform-text {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  line-height: 170%;
}

.primary-heading {
  color: #000;
  font-family: var(--header-font);
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 1.1;
  margin-bottom: 2rem;
  margin-top: 0;
  display: block;
}

.welcome-text {
  font-size: 14px;
  line-height: 150%;
}

.animation {
  animation: form-slide 0.5s ease forwards;
}

@keyframes form-slide {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.new-styles {
  .text-center {
    text-align: center;
  }
}
</style>
