import { RsForm } from '@rscollabs/rs-ui-library/src/components';
import { TranslateResult } from 'vue-i18n';

type RewardStyleForm = typeof RsForm;

export interface RsFormComponent extends RewardStyleForm {
  validate(): boolean;
}

interface Auth0ConfigExtraParams {
  _csrf: string;
  _intstate: string;
  audience: string;
  consentProvided?: string;
  screen_hint?: string;
  login_hint?: string;
  response_type: string;
  response_mode: string;
  nonce: string;
  auth0Client: string;
  code_challenge: string;
  code_challenge_method: string;
  prompt: string;
  protocol: string;
  scope: string;
  state: string;
}

interface Auth0AuthServer {
  issuer: string;
  url: string;
}

export interface Auth0ConfigParams {
  assetsUrl: '';
  auth0Domain: string;
  auth0Tenant: string;
  authorizationServer: Auth0AuthServer;
  callbackOnLocationHash: boolean;
  callbackURL: string;
  cdn: string;
  clientConfigurationBaseUrl: string;
  clientID: string;
  colors: Colors;
  connection?: string;
  dict: Record<string, Record<string, string>>;
  extraParams: Auth0ConfigExtraParams;
  icon: string;
  internalOptions: Auth0ConfigExtraParams;
  isThirdPartyClient: boolean;
  widgetUrl: string;
}

export enum routeNames {
  LOGIN = 'login',
  SIGNUP = 'signup',
  FORGOT_PASSWORD = 'forgot-password',
  CONFIRMATION = 'confirmation',
  NOT_FOUND = '404',
}

export interface Auth0Dict {
  signin: Signin;
}

export interface Signin {
  title: string;
}

export interface ExtraParams {
  protocol: string;
  _csrf: string;
  _intstate: string;
  state: string;
}

export interface InternalOptions {
  protocol: string;
  _csrf: string;
  _intstate: string;
  state: string;
}

export interface AuthorizationServer {
  url: string;
  issuer: string;
}

export interface Colors {
  page_background: string;
  primary: string;
}

declare global {
  interface Window {
    configParams?: Auth0ConfigParams;
  }
}

export interface ErrorHandler {
  showError: boolean;
  message: string | TranslateResult | null;
}
