<template>
  <creator-login-wrapper form-width="420px">
    <rs-container>
      <rs-layout class="column animation welcome-text">
        <rs-flex shrink :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }">
          <rs-icon color="#000" size="40" v-if="!$vuetify.breakpoint.smAndDown">rsfont-ltk-logo_v2</rs-icon>
          <h1 data-test-id="default-login-title" class="primary-heading" v-t="'Sign up'"></h1>
          <div>
            <p class="mt-2 mb-3">
              <span data-test-id="default-login-text" v-t="'Create an LTK Creator account'"></span>
            </p>
          </div>
        </rs-flex>
      </rs-layout>
      <div class="mt-3 animation">
        <signup-form />
      </div>
    </rs-container>
  </creator-login-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CreatorLoginWrapper from '@/components/CreatorLoginWrapper.vue';
import SignupForm from '@/components/SignupForm.vue';

export default defineComponent({
  components: {
    CreatorLoginWrapper,
    SignupForm,
  },
  name: 'SignupPage',
  setup() {},
});
</script>

<style lang="scss" scoped>
.primary-heading {
  color: #000;
  font-family: var(--header-font);
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 1.1;
  margin-bottom: 2rem;
  margin-top: 0;
  display: block;
}

.welcome-text {
  font-size: 14px;
  line-height: 150%;
  text-align: center;
}

.animation {
  animation: form-slide 0.8s ease forwards;
}

@keyframes form-slide {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
