<template>
  <div class="mt-3 form-animation">
    <rs-form ref="form" v-model="isValid" @submit.prevent="handleEmailSubmit" id="signup-form">
      <div class="error--text mb-4" v-show="hasAuthError">
        <span v-html="globalAuthError"></span>
      </div>
      <rs-text-field
        class="mb-1"
        v-model="formData.email"
        :rules="emailRule"
        :label="labels.email"
        required
        data-test-id="signup-email-input"
        :error="hasAuthError"
        outline
        :disabled="hasLoginHint"
        @keyup="handleFormFieldChange"
      ></rs-text-field>
      <rs-text-field
        class="mb-2"
        v-model="formData.password"
        :rules="passwordRule"
        :label="labels.password"
        required
        data-test-id="signup-password-input"
        @click:append="visible = !visible"
        :append-icon="visible ? 'visibility' : 'visibility_off'"
        :type="visible ? 'text' : 'password'"
        :error="hasAuthError"
        outline
        hide-details
        @keyup="handleFormFieldChange"
      ></rs-text-field>
      <password-checker :password="formData.password" />
      <button
        data-test-id="signup-submit-btn"
        class="rs-button loginBtn mt-4 mb-4"
        type="submit"
        :disabled="authIsLoading || !isValid"
        @click.prevent="handleEmailSubmit"
      >
        {{ labels.continue }}
      </button>
    </rs-form>
    <sso-buttons :login-screen="false" v-if="hasSSOFeatureFlag" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { RsFormComponent } from '@/types';
import PasswordChecker from '@/components/PasswordChecker.vue';
import SsoButtons from '@/components/SsoButtons.vue';
import { isFlagActive, FeatureFlags } from '@/utils/featureFlags';
import useSSOAuthentication from '@/composables/useSSOAuthentication';
import useAuthentication from '@/composables/useAuthentication';

export default defineComponent({
  name: 'SignupForm',
  components: { PasswordChecker, SsoButtons },
  setup() {
    const vm = getCurrentInstance();
    const { $amplitude } = vm?.proxy as Vue;
    const { automaticSsoFromQuery } = useSSOAuthentication();
    const { signup, hasAuthError, globalAuthError, authIsLoading } = useAuthentication();
    const { t } = useI18n();

    const visible = ref(false);
    const isValid = ref(false);
    const form = ref<RsFormComponent | null>(null);

    const newUrlParams = window.location ? new URLSearchParams(window.location.search) : null;
    const hasLoginHint = Boolean(newUrlParams?.get('login_hint')) || false;
    const hasSSOFeatureFlag = computed(() => isFlagActive(FeatureFlags.SSO));

    const formData = reactive({
      email: newUrlParams?.get('login_hint') || '',
      password: '',
    });

    const emailRule = computed(() => [
      (v: string) => !!v || t('Email is required'),
      (v: string) => /.+@.+/.test(v) || t('Email is invalid'),
    ]);

    const passwordRule = computed(() => [
      (v: string) => !!v || t('Password is required'),
      (v: string) => v.length >= 8 || t('8 Character minimum'),
      (v: string) => /.*[a-z]/.test(v) || t('Password needs a lowercase'),
      (v: string) => /.*[A-Z]/.test(v) || t('Password needs a uppercase'),
      (v: string) => /.*[^a-zA-Z]/.test(v) || t('Password needs a special character'), // copied rules from sigil
    ]);

    const labels = computed(() => ({
      email: t('Enter your email'),
      password: t('Password'),
      continue: t('Continue'),
    }));

    const handleFormFieldChange = () => {
      authIsLoading.value = false;
      hasAuthError.value = false;
    };

    const handleEmailSubmit = async () => {
      if (!isValid.value) return false;

      hasAuthError.value = false;

      $amplitude?.track('/auth/apply/sign-up/email');

      try {
        await signup(formData);
      } catch (e) {
        $amplitude?.track('/auth/signup/failure');
      }
    };

    // Triggers SSO from Query
    if (hasSSOFeatureFlag.value) {
      automaticSsoFromQuery();
    }

    return {
      formData,
      form,
      authIsLoading,
      isValid,
      handleEmailSubmit,
      handleFormFieldChange,
      hasAuthError,
      globalAuthError,
      labels,
      visible,
      emailRule,
      passwordRule,
      hasLoginHint,
      hasSSOFeatureFlag,
    };
  },
});
</script>

<style lang="scss" scoped>
#signup-form {
  #signup-button {
    background-color: #000;
    border-radius: 4px !important;
    border: none !important;
    color: #fff !important;

    &:disabled {
      color: var(--grey-40) !important;
    }
  }

  .signupBtn {
    margin-top: 40px;
  }
}

.form-animation {
  animation: form-slide 0.8s ease forwards;
}

@keyframes form-slide {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>

<style lang="scss">
.v-btn {
  .v-btn__content {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &:focus {
      border: none;
    }
  }

  &:disabled {
    background: var(--grey-10);
    color: var(--grey-40);
  }
}

.v-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.005em;
  margin-bottom: 58px;
  color: var(--grey-50) !important;
}

.v-label--active {
  top: -5px;
}

.theme--light.v-text-field--outline:not(.error--text) > .v-input__control > .v-input__slot {
  border-color: var(--grey-50) !important;
}
.v-text-field--box .v-label--active,
.v-text-field--full-width .v-label--active,
.v-text-field--outline .v-label--active {
  transform: translateY(-27px) scale(0.85) !important;
  background-color: white !important;
  padding: 0 4px;
}
</style>
