<template>
  <div class="container-wrap">
    <rs-container>
      <rs-layout align-center class="form-container">
        <rs-flex xs12 md7>
          <rs-layout
            justify-center
            column
            class="form-layout fill-height ma-auto"
            data-test-id="content-wrapper"
            :style="{ 'max-width': formWidth }"
          >
            <slot></slot>
          </rs-layout>
        </rs-flex>
        <rs-flex xs12 md5 class="influencer-login-img" data-test-id="influencer-image">
          <span class="overlay">
            <span class="influencer-tag" data-test-id="influencer-credit"> @RIE_DEFINED </span>
          </span>
        </rs-flex>
      </rs-layout>
    </rs-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CreatorLoginWrapper',
  props: {
    formWidth: {
      type: String,
      default: '320px',
    },
  },
});
</script>

<style lang="scss" scoped>
@use '../style/_breakpoint.scss';

.container-wrap {
  width: 100%;
  height: 100%;
}

::v-deep {
  .v-text-field {
    padding-top: 20px;
  }

  .v-content {
    @media (min-width: 960px) {
      align-items: center;
    }
  }
}

.container.fluid {
  max-width: 100% !important;
}
.form-layout {
  margin: auto;
  width: 100vw;
  @include breakpoint.down(sm) {
    padding: 0 16px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100%;

  @media (max-width: 375px) {
    min-height: 375px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @media (min-width: 960px) {
    min-height: 550px;
  }
  @media (min-width: 1264px) {
    min-height: 650px;
  }
  @media (min-width: 1900px) {
    margin: auto;
  }
}

.form-container .container {
  margin: auto;
}

.container {
  height: 100%;
  margin: 0;
  padding: 0 !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.container {
  height: 100%;
  margin: 0;
  padding: 0 !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.influencer-login-img {
  background: url('../assets/rie-defined.png');
  background-position: top;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  display: none;
  @media (min-width: 960px) {
    display: flex;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);

  > .influencer-tag {
    font-family:
      SofiaPro,
      sans-serif,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Oxygen,
      Ubuntu,
      Droid Sans,
      Helvetica Neue;
    position: absolute;
    bottom: 48px;
    right: 48px;
    z-index: 2;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
}

.fill-height {
  height: 100%;
}
</style>
