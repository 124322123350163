<template>
  <div class="ltk-password-checker">
    <div
      class="ltk-password-checker__requirement"
      :class="{ 'is-active': lengthCheck }"
      data-testid="password-check-length"
    >
      <span class="icon-wrapper">
        <rs-icon size="12" color="var(--success-50)" v-if="lengthCheck">rsfont-check-mark-right</rs-icon>
        <span class="sr-only" v-t="'Requirement met'" v-if="lengthCheck"></span>
      </span>
      <span v-t="'8 Character minimum'"></span>
    </div>
    <div
      class="ltk-password-checker__requirement"
      :class="{ 'is-active': uppercaseCheck }"
      data-testid="password-check-uppercase"
    >
      <span class="icon-wrapper">
        <rs-icon size="12" color="var(--success-50)" v-if="uppercaseCheck">rsfont-check-mark-right</rs-icon>
        <span class="sr-only" v-t="'Requirement met'" v-if="uppercaseCheck"></span>
      </span>
      <span v-t="'Uppercase'"></span>
    </div>
    <div
      class="ltk-password-checker__requirement"
      :class="{ 'is-active': lowercaseCheck }"
      data-testid="password-check-lowercase"
    >
      <span class="icon-wrapper">
        <rs-icon size="12" color="var(--success-50)" v-if="lowercaseCheck">rsfont-check-mark-right</rs-icon>
        <span class="sr-only" v-t="'Requirement met'" v-if="lowercaseCheck"></span>
      </span>
      <span v-t="'Lowercase'"></span>
    </div>
    <div
      class="ltk-password-checker__requirement"
      :class="{ 'is-active': specialCharCheck }"
      data-testid="password-check-special-char"
    >
      <span class="icon-wrapper">
        <rs-icon size="12" color="var(--success-50)" v-if="specialCharCheck">rsfont-check-mark-right</rs-icon>
        <span class="sr-only" v-t="'Requirement met'" v-if="specialCharCheck"></span>
      </span>
      <span v-t="'Special character'"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, toRefs } from 'vue';

export default defineComponent({
  name: 'PasswordChecker',
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { password } = toRefs(props);
    const lengthCheck = computed(() => password.value.length >= 8);
    const lowercaseCheck = computed(() => /.*[a-z]/.test(password.value));
    const uppercaseCheck = computed(() => /.*[A-Z]/.test(password.value));
    const specialCharCheck = computed(() => /.*[^a-zA-Z]/.test(password.value));

    return { lengthCheck, lowercaseCheck, uppercaseCheck, specialCharCheck };
  },
});
</script>

<style lang="scss">
.ltk-password-checker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &__requirement {
    width: 50%;
    flex: 50%;

    span.icon-wrapper {
      width: 12px;
      display: inline-block;
      margin-right: 8px;
    }

    &.is-active {
      span {
        color: var(--success-50);
      }
    }
  }
}
</style>
