import useAuthentication from '@/composables/useAuthentication';
import { Auth0Error } from 'auth0-js';

export const ssoProviders = {
  apple: {
    id: 'apple',
    connection: 'apple',
    icon: 'mdi-apple',
    name: 'apple',
  },
  facebook: {
    id: 'facebook',
    connection: 'facebook',
    icon: 'mdi-facebook',
    name: 'facebook',
  },
  google: {
    id: 'google',
    connection: 'google-oauth2',
    icon: 'mdi-google',
    name: 'google',
  },
};

export type SsoProvider = keyof typeof ssoProviders;
export type SsoConnection = (typeof ssoProviders)[SsoProvider]['connection'];

/**
 * Ordered list of sso provider options, by key/id
 */
export const ssoOptions: readonly SsoProvider[] = ['apple', 'facebook', 'google'] as const;

const useSSOAuthentication = () => {
  const { authClient, hasAuthError, authIsLoading, handleAuthError, newUrlParams } = useAuthentication();

  /**
   *
   * @param connection Name of the connection as defined in auth0
   * @param prompt optional prompt to send to the 3rd party authorization
   */
  const login = async (connection: SsoConnection, prompt = 'login') => {
    hasAuthError.value = false;
    authIsLoading.value = true;
    try {
      await authClient?.authorize({ connection, prompt });
    } catch (err) {
      handleAuthError(err as Auth0Error);
    }
  };

  const automaticSsoFromQuery = () => {
    const platformId = newUrlParams?.get('connection');

    if (platformId && platformId in ssoProviders) {
      const provider = ssoProviders[platformId as SsoProvider];

      login(provider.connection);
    }
  };

  return { automaticSsoFromQuery, login, ssoProviders, authClient };
};

export default useSSOAuthentication;
